var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reworkersManageImport-wrapper" },
    [
      _c(
        "import-template",
        {
          attrs: {
            title: "导入数据",
            uploadUrl: _vm.importURL,
            downloadUrl: _vm.downloadUrl,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "remark",
              attrs: { slot: "remark" },
              slot: "remark",
            },
            [_c("div", [_vm._v("请下载导入模板，填入数据后上传附件")])]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }