// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}emergency/getUserResumptionWorkList`
// 确认、取消复工
const approveReworkURL = `${API_CONFIG.butlerBaseURL}emergency/approveResumptionWork`
// 导出用户信息待审核
const importURL = `${API_CONFIG.butlerBaseURL}emergency/importResumptionUser`
// 获取用户详情
const getUserInfoURL = `${API_CONFIG.butlerBaseURL}emergency/getOwnerDetails`
// 审核用户
const approveUserURL = `${API_CONFIG.butlerBaseURL}emergency/healthApprove`

export {
  getListURL,
  approveReworkURL,
  importURL,
  getUserInfoURL,
  approveUserURL
}
